export const countResults = (results, memberExternalId) => {
	let count = 0;
	Object.keys(results).forEach((key) => {
		if (
			![
				'getContactUsForms',
				'getTransitionGuides',
				'getOhepEoiForms',
				'getOhepNominationForms'
			].includes(key)
		) {
			count += results[key].length;
		} else {
			const filtered = results[key].filter(
				(result) => result?.member?.externalId === memberExternalId
			);
			count += filtered.length;
		}
	});
	return count;
};
