import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import Footer from '../components/Footer';
import IndexPage from '../components/IndexPage';
import Login from '../components/Login';
import MainContainer from '../components/MainContainer';
import Dashboard from '../components/Dashboard';
import OTTNewsreel from '../components/OTTNewsreel';
import Register from '../components/Register';
import { AuthProvider, useAuth } from '../hooks/useAuth';
import HorseProfile from '../components/HorseProfile';
import UnclaimThoroughbred from '../components/UnclaimThoroughbred';
import { ScreenClassProvider } from 'react-grid-system';
import { ThemeProvider } from 'styled-components';
import ClaimThoroughbred from '../components/ClaimThoroughbred';
import Profile from '../components/Profile';
import { ToastProvider } from '../hooks/useToast';
import { FilePreviewProvider } from '../hooks/useFilePreview';
import FullArticle from '../components/FullArticle';
import ReviewList from '../components/ReviewList';
import FullCircleForm from '../components/FullCircleForm';
import GlobalSearch from '../components/GlobalSearch';
import BusinessProfileReview from '../components/BusinessProfileReview';
import PasswordReset from '../components/PasswordReset';
import ThoroughbredRegistration from '../components/ThoroughbredRegistration';
import FullCircleReview from '../components/FullCircleReview';
import FullCircle from '../components/FullCircle';
import RetrainerProfileTemplate from '../components/RetrainerProfileTemplate';
import VerificationLandingPage from '../components/VerificationLandingPage';
import ComingSoon from '../components/ComingSoon';
import PrivacyPolicy from '../components/PrivacyPolicy';
import OHEPPrivacyPolicy from '../components/OHEPPrivacyPolicy';
import PrivacyCollectionStatement from '../components/PrivacyCollectionStatement';
import ClaimThoroughbredInfo from '../components/ClaimThoroughbredInfo';
import TermsAndConditions from '../components/TermsAndConditions';
import OTTTackRoom from '../components/OTTTackRoom';
import ImNew from '../components/ImNew';
import Store from '../utils/reducers/store';
import ThoroughbredRegistrationReview from '../components/ThoroughbredRegistrationReview';
import OTTArticle from '../components/OTTArticle';
import HorseDetails from '../components/HorseDetails';
import AvailableForRetrainReview from '../components/AvailableForRetrainReview';
import HorsePlacement from '../components/HorsePlacement';
import ResetInfo from '../components/ResetInfo';
import StyleGuide from '../styleguide';
import ResetForm from '../components/ResetForm';
import ShareInformationForm from '../components/ShareInformationForm';
import PhotoUploadGuide from '../components/PhotoUploadGuide';
import ResetFormReview from '../components/ResetFormReview';
import ShareInformationReview from '../components/ShareInformationReview';
import ErrorBoundary from '../components/ErrorBoundary';
import AcknowledgedRetrainer from '../components/AcknowledgedRetrainer';
import RESETConcludedForm from '../components/RESETConcludedForm';
import AcknowledgedRetrainerInfo from '../components/AcknowledgedRetrainerInfo';
import AcknowledgedRetrainerProgramInfo from '../components/AcknowledgedRetrainerProgramInfo';
import HorseProfileEdit from '../components/HorseProfileEdit';
import RetrainerOfTheYear from '../components/RetrainerOfTheYear';
import MemberAdminView from '../components/MemberAdminView';
import AcknowledgedRetrainerReview from '../components/AcknowledgedRetainerReview';
import ProtectedRoute from '../components/ProtectedRoute';
import RetrainerPrivacyPolicy from '../components/RetrainerPrivacyPolicy';
import ResetPrivacyPolicy from '../components/ResetPrivacyPolicy';
import CompetitionTermsAndConditions from '../components/CompetitionTermsAndConditions';
import CompetitionPage from '../components/CompetitionPage';
import AddEvent from '../components/AddEvent';
import EventDrafts from '../components/EventDrafts';
import EventsLandingPage from '../components/EventsLandingPage';
import Events from '../components/Events';
import RetirementPrivacyPolicy from '../components/RetirementPrivacyPolicy';
import {
	CLAIM_THOROUGHBRED_REVIEW__ID_ROUTE,
	DASHBOARD_ROUTE,
	LOGIN_ROUTE,
	OHEP_FORM_REVIEW__ID_ROUTE,
	OHEP_FORM__ID_ROUTE,
	OTT_PARTNERSHIPS_ROUTE,
	PASSWORD_RESET_ROUTE,
	PREVIOUS_CLAIM_THOROUGHBRED_REVIEW__ID_ROUTE,
	PROGRAMS_OHEP_LANDING_PAGE_ROUTE,
	OHEP_EOI_FORM__ID_ROUTE,
	OHEP_EOI_FORM__ROUTE,
	OHEP_EOI_FORM_REVIEW__ID_ROUTE,
	OHEP_NTP_FORM__ID_ROUTE,
	ACKNOWLEDGED_RETRAINER_PRIVACY_COLLECTION_STATEMENT_ROUTE,
	OHEP_PROVIDERS_ROUTE,
	ACKNOWLEDGED_RETIREMENT_FARM_PRIVACY_COLLECTION_STATEMENT_ROUTE,
	SAFETY_NET_PROGRAM_REPORTS_ROUTE,
	DECEASED_THOROUGHBRED_FORM_ROUTE,
	THOROUGHBRED_UNREGISTERED_FORM,
	THOROUGHBRED_UNREGISTERED_FORM__ID_ROUTE,
	THOROUGHBRED_UNREGISTERED_FORM_REVIEW__ID_ROUTE,
	EVENT_REPORTING_ROUTE,
	EVENT_REPORTING_REQUEST_ROUTE,
	SPONSORSHIP_OUTCOME_ID_ROUTE,
	SPONSORSHIP_OUTCOME_ROUTE,
	SPONSORSHIP_OUTCOME_REVIEW_ROUTE
} from '../utils/constants/routes';
import Event from '../components/Event';
import ProgramsLandingPage from '../components/ProgramsLandingPage';
import TransitionGuideAdminForm from '../components/TransitionGuideAdminForm';
import TransitionGuide from '../components/TransitionGuide';
import theme from '../config/theme';
import EventPartnerships from '../components/EventPartnerships';
import ContactUsPage from '../components/ContactUsPage';
import ContactUsOutcome from '../components/ContactUsOutcome';
import ContactUsReview from '../components/ContactUsReview';
import ViewNotifications from '../components/ViewNotifications';
import CurrentClaimThoroughbredReview from '../components/CurrentClaimThoroughbredReview';
import PreviousClaimThoroughbredReview from '../components/PreviousClaimThoroughbredReview';
import { Amplify } from 'aws-amplify';
import {
	APIS_CONFIG,
	APPSYNC_CONFIG,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	ROLE_COMPETITION_ADMIN,
	ROLE_RV_EQUINE_WELFARE
} from '../utils/constants';
import AcknowledgedRetirementFarmInfo from '../components/AcknowledgedRetirementFarmInfo';
import EquineBusinessGrantLandingPage from '../components/EquineBusinessGrantLandingPage';
import SafetyNetProgramLandingPage from '../components/SafetyNetProgramLandingPage';
import OHEPForm from '../components/OHEPForm';
import OHEPLandingPage from '../components/OHEPLandingPage';
import ConcludeOHEP from '../components/ConcludeOHEP';
import OhepReviewForm from '../components/OhepFormReview';
import OHEPEOIForm from '../components/OHEPEOIForm';
import OHEPEOIReview from '../components/OHEPEOIReview';
import OHEPNoticeToProceedForm from '../components/OHEPNoticeToProceedForm';
import OHEPProviders from '../components/OHEPProviders';
import SearchMultipleHorses from '../components/SearchMultipleHorses';
import {
	FOSTER,
	RETIREMENT,
	RETRAINER
} from '../components/RetrainerProfileTemplate/constants';
import AcknowledgedRetirementForm from '../components/AcknowledgedRetirementForm';
import SafetyNetProgramReview from '../components/SafetyNetProgramReview';
import ThoroughbredUnregisteredForm from '../components/ThoroughbredUnregisteredForm';
import NotFoundPage from './404';
import DeceasedThoroughbredForm from '../components/DeceasedThoroughbredForm';
import ThoroughbredUnregisteredFormReview from '../components/ThoroughbredUnregisteredFormReview';
import EventReporting from '../components/EventReporting';
import EventReportingRequest from '../components/EventReportingRequest';
import SponsorshipOutcomeForm from '../components/SponsorshipOutcomeForm';
import SponsorshipOutcomeFormReview from '../components/SponsorshipOutcomeFormReview';
import TransitionReport from '../components/TransitionReport';
import RehabilitationSubsidyReview from '../components/RehabilitationSubsidyReview';
import CompanionHorseReport from '../components/CompanionHorseReport';
import MembersAreaLandingPage from '../components/MembersAreaLandingPage';
import AcknowledgedRehomersInfo from '../components/AcknowledgedRehomersInfo';
import RetiredThoroughbredCompanionsInfo from '../components/RetiredThoroughbredCompanionsInfo';

// Check first the window object is defined because of build failing of window object being undefined in build server
if (typeof window !== 'undefined') {
	// clear the storage if the mentioned variable is not defined which will result in a logout
	if (!localStorage.getItem('lastStorageResetJan2022')) {
		localStorage.clear();
		localStorage.setItem('lastStorageResetJan2022', 'true');
	}
}

Amplify.configure({
	...APPSYNC_CONFIG,
	...APIS_CONFIG
});

const LoginConditionalRoute = ({
	loggedInComponent: LoggedInComponent,
	guestComponent: GuestComponent,
	...props
}) => {
	const [isAuthenticated, setLoggedIn] = useState(true);
	const { getCurrentUser, signOut } = useAuth();
	useEffect(() => {
		(async () => {
			let user = null;
			try {
				user = await getCurrentUser();
				if (user) {
					setLoggedIn(true);
				} else {
					setLoggedIn(false);
				}
			} catch (e) {
				setLoggedIn(false);
			}
		})();
	});

	if (isAuthenticated) {
		return <LoggedInComponent {...props} />;
	} else {
		signOut();
		return <GuestComponent {...props} />;
	}
};

const Index = () => (
	<AuthProvider>
		<ScreenClassProvider>
			<ToastProvider>
				<FilePreviewProvider>
					<ThemeProvider theme={theme}>
						<Store>
							<MainContainer>
								<ErrorBoundary>
									<Router>
										<LoginConditionalRoute
											loggedInComponent={Dashboard}
											guestComponent={IndexPage}
											path={DASHBOARD_ROUTE}
											exact
										/>
										<Login path={LOGIN_ROUTE} />
										<PasswordReset path={PASSWORD_RESET_ROUTE} />
										<Register path="/register" />
										<ProtectedRoute component={Profile} path="/account" />
										<HorseProfile path="/horse-profile/:horseCode" />
										<ProtectedRoute
											component={HorseProfileEdit}
											path="/horse-profile-edit/:horseCode"
										/>
										<ProtectedRoute
											component={UnclaimThoroughbred}
											path="/unclaim-thoroughbred/:horseCode"
										/>
										<ProtectedRoute
											component={ClaimThoroughbred}
											path="/claim-thoroughbred-form/:horseCode"
										/>
										<ProtectedRoute
											component={ShareInformationForm}
											path="/share-information-form/:horseCode"
										/>
										<PrivacyPolicy path="/privacy" />
										<TermsAndConditions path="/terms" />
										<PrivacyCollectionStatement path="/privacy-collection-statement" />
										<ProtectedRoute
											component={CurrentClaimThoroughbredReview}
											path={`${CLAIM_THOROUGHBRED_REVIEW__ID_ROUTE}/:id`}
											adminOnly
										/>
										<ProtectedRoute
											component={PreviousClaimThoroughbredReview}
											path={`${PREVIOUS_CLAIM_THOROUGHBRED_REVIEW__ID_ROUTE}/:id`}
											adminOnly
										/>
										<ProtectedRoute
											component={BusinessProfileReview}
											path="/business-profile-review/:id"
											adminOnly
										/>
										<ProtectedRoute
											component={FullCircleReview}
											path="/full-circle-review/:id"
											adminOnly
										/>
										<ProtectedRoute
											component={ThoroughbredRegistrationReview}
											path="/thoroughbred-registration-review/:id"
											adminOnly
										/>
										<ProtectedRoute
											component={AvailableForRetrainReview}
											path="/available-for-retraining/:id"
											adminOnly
										/>
										<ProtectedRoute
											component={RehabilitationSubsidyReview}
											path="/rehabilitation-subsidy/:id"
											adminOnly
										/>
										<OTTNewsreel path="/ott-news-reel/:tag" />
										<ProtectedRoute
											component={AcknowledgedRetrainer}
											path="/acknowledged-retrainer-form"
										/>
										<ProtectedRoute
											component={RetrainerPrivacyPolicy}
											path={
												ACKNOWLEDGED_RETRAINER_PRIVACY_COLLECTION_STATEMENT_ROUTE
											}
										/>
										<ProtectedRoute
											component={RetirementPrivacyPolicy}
											path={
												ACKNOWLEDGED_RETIREMENT_FARM_PRIVACY_COLLECTION_STATEMENT_ROUTE
											}
										/>
										<ProtectedRoute
											component={AcknowledgedRetrainerReview}
											path="/acknowledged-retrainer-form-review/:id"
											adminOnly
											formType={RETRAINER}
										/>
										<ProtectedRoute
											component={AcknowledgedRetrainerReview}
											path="/acknowledged-retirement-form-review/:id"
											adminOnly
											formType={RETIREMENT}
										/>
										<FullArticle path="/ott-news/:articleId" />
										<ProtectedRoute
											component={ReviewList}
											path="/review-list"
											adminOnly
										/>
										<ProtectedRoute
											component={TransitionGuideAdminForm}
											path="/transition-guide-form/:id"
											adminOnly
										/>
										<ProtectedRoute
											component={FullCircleForm}
											path="/full-circle-form/:horseCode"
										/>
										<ProtectedRoute
											component={HorsePlacement}
											path="/horse-placement-form/:id"
										/>
										<ProtectedRoute
											component={ThoroughbredRegistration}
											path="/thoroughbred-registration-form/:id"
										/>
										<ProtectedRoute
											component={ThoroughbredRegistration}
											path="/thoroughbred-registration-form"
										/>
										<ProtectedRoute
											component={RetrainerProfileTemplate}
											profileType={RETRAINER}
											path="/retrainer-profile/:id"
										/>
										<ProtectedRoute
											component={RetrainerProfileTemplate}
											profileType={RETIREMENT}
											path="/retirement-farm-profile/:id"
										/>
										<ProtectedRoute
											component={RetrainerProfileTemplate}
											profileType={FOSTER}
											path="/star-provider-profile/:id"
											rolesRequired={[
												ROLE_RV_EQUINE_WELFARE,
												ROLE_ACKNOWLEDGED_FOSTER_CARER
											]}
											isUseNotFoundPage={true}
										/>
										<ProtectedRoute
											component={AcknowledgedRetirementForm}
											path="/acknowledged-retirement-form-application"
										/>
										<ProtectedRoute
											component={HorseDetails}
											path="/horse-details/:id"
										/>
										<GlobalSearch path="/search" />
										<ProtectedRoute
											path="/search/multiple-horses"
											rolesRequired={[
												ROLE_RV_EQUINE_WELFARE,
												ROLE_COMPETITION_ADMIN
											]}
											component={SearchMultipleHorses}
										/>
										<ProtectedRoute
											component={ResetForm}
											path="/reset-form/:id"
										/>
										<ProtectedRoute
											component={ResetFormReview}
											path="/reset-form-review/:id"
											adminOnly
										/>
										<ProtectedRoute
											component={ResetPrivacyPolicy}
											path="/reset-form-privacy-policy"
										/>
										<ProtectedRoute
											component={ShareInformationReview}
											path="/share-information-form-review/:id"
											adminOnly
										/>
										<ProtectedRoute
											component={RESETConcludedForm}
											path="/reset-concluded-form/:horseCode"
										/>
										<ProtectedRoute
											component={OHEPForm}
											path={OHEP_FORM__ID_ROUTE}
										/>
										<ProtectedRoute
											component={OhepReviewForm}
											path={OHEP_FORM_REVIEW__ID_ROUTE}
											adminOnly
										/>
										<ProtectedRoute
											component={OHEPNoticeToProceedForm}
											path={OHEP_NTP_FORM__ID_ROUTE}
										/>
										<ProtectedRoute
											component={MemberAdminView}
											path="/member/:id"
											adminOnly
										/>
										<ProgramsLandingPage path="/programs" />
										<MembersAreaLandingPage path="/members-area" />
										<FullCircle path="/programs/full-circle" />
										<VerificationLandingPage path="/verification-landing-page/:id" />
										<ComingSoon path="/coming-soon/" />
										<ClaimThoroughbredInfo path="/programs/claim-thoroughbred" />
										<OTTTackRoom path="/ott-tack-room" />
										<ResetInfo path="/programs/reset-landing-page" />
										<AcknowledgedRetrainerInfo path="/programs/acknowledged-retrainers-landing-page" />
										<AcknowledgedRetrainerProgramInfo path="/acknowledged-retrainers-program" />
										<RetrainerOfTheYear path="/retrainer-of-the-year" />
										<ImNew path="/im-new" />
										<OTTArticle path="/ott-tack-room/:articleId" />
										<PhotoUploadGuide path="/photo-upload-guide" />
										<CompetitionTermsAndConditions path="/competition-terms-and-conditions" />
										<AcknowledgedRetirementFarmInfo path="/programs/acknowledged-retirement-farms-page" />
										<AcknowledgedRehomersInfo path="/programs/acknowledged-rehomers" />
										<RetiredThoroughbredCompanionsInfo path="/programs/retired-thoroughbred-companions" />
										<ProtectedRoute
											component={TransitionGuide}
											path="/transition-guide"
										/>
										<ProtectedRoute
											component={ViewNotifications}
											path="/all-notifications"
										/>
										<ProtectedRoute
											component={CompetitionPage}
											path="/competitions"
										/>
										<ProtectedRoute
											component={AddEvent}
											path="/add-event/:id"
											adminOnly
											rolesRequired={[
												ROLE_RV_EQUINE_WELFARE,
												ROLE_COMPETITION_ADMIN
											]}
										/>
										<ProtectedRoute
											component={AddEvent}
											path="/add-event"
											adminOnly
											rolesRequired={[
												ROLE_RV_EQUINE_WELFARE,
												ROLE_COMPETITION_ADMIN
											]}
										/>
										<ProtectedRoute
											component={EventDrafts}
											path="/event-drafts"
											adminOnly
										/>
										<ProtectedRoute component={Event} path="/event/:id" />
										<EventsLandingPage path="/ott-events" />
										<EventPartnerships path={OTT_PARTNERSHIPS_ROUTE} />
										<ProtectedRoute component={Events} path="/events" />
										{process.env.GATSBY_APP_ENVIRONMENT === 'development' && (
											<StyleGuide path="/styleguide" />
										)}
										<ContactUsPage path="/contact-us" />
										<ContactUsOutcome path="/contact-outcome" />
										<ContactUsReview path="/contact-us-form/:id" adminOnly />
										<EquineBusinessGrantLandingPage path="/programs/life-after-racing" />
										<SafetyNetProgramLandingPage path="/programs/safety-net-program" />
										<ProtectedRoute
											component={OHEPPrivacyPolicy}
											path="/ohep-privacy"
										/>
										<ProtectedRoute
											component={OHEPProviders}
											path={OHEP_PROVIDERS_ROUTE}
										/>
										<ProtectedRoute
											component={OHEPLandingPage}
											path={PROGRAMS_OHEP_LANDING_PAGE_ROUTE}
										/>
										<ProtectedRoute
											component={ConcludeOHEP}
											path={'/conclude-ohep/:horseCode'}
											adminOnly
										/>
										<ProtectedRoute
											component={OHEPEOIForm}
											path={OHEP_EOI_FORM__ID_ROUTE}
										/>
										<ProtectedRoute
											component={OHEPEOIForm}
											path={OHEP_EOI_FORM__ROUTE}
										/>
										<ProtectedRoute
											component={OHEPEOIReview}
											path={OHEP_EOI_FORM_REVIEW__ID_ROUTE}
											adminOnly
										/>
										<ProtectedRoute
											component={SafetyNetProgramReview}
											path={SAFETY_NET_PROGRAM_REPORTS_ROUTE}
											adminOnly
										/>
										<ProtectedRoute
											component={DeceasedThoroughbredForm}
											path={DECEASED_THOROUGHBRED_FORM_ROUTE}
											adminOnly
										/>
										<ProtectedRoute
											component={ThoroughbredUnregisteredForm}
											path={THOROUGHBRED_UNREGISTERED_FORM__ID_ROUTE}
										/>
										<ProtectedRoute
											component={ThoroughbredUnregisteredForm}
											path={THOROUGHBRED_UNREGISTERED_FORM}
										/>
										<ProtectedRoute
											component={ThoroughbredUnregisteredFormReview}
											path={THOROUGHBRED_UNREGISTERED_FORM_REVIEW__ID_ROUTE}
										/>
										<ProtectedRoute
											component={EventReporting}
											path={EVENT_REPORTING_ROUTE}
											rolesRequired={[
												ROLE_RV_EQUINE_WELFARE,
												ROLE_COMPETITION_ADMIN
											]}
										/>
										<ProtectedRoute
											component={EventReportingRequest}
											path={EVENT_REPORTING_REQUEST_ROUTE}
											adminOnly
										/>
										<ProtectedRoute
											component={SponsorshipOutcomeForm}
											path={SPONSORSHIP_OUTCOME_ROUTE}
											rolesRequired={[ROLE_COMPETITION_ADMIN]}
										/>
										<ProtectedRoute
											component={SponsorshipOutcomeForm}
											path={SPONSORSHIP_OUTCOME_ID_ROUTE}
											rolesRequired={[ROLE_COMPETITION_ADMIN]}
										/>
										<ProtectedRoute
											component={SponsorshipOutcomeFormReview}
											path={`${SPONSORSHIP_OUTCOME_REVIEW_ROUTE}/:id`}
										/>
										<ProtectedRoute
											component={TransitionReport}
											path="/transition-report/:horseCode"
										/>
										<ProtectedRoute
											component={TransitionReport}
											path="/transition-report-by-id/:id"
											rolesRequired={[ROLE_RV_EQUINE_WELFARE]}
										/>
										<ProtectedRoute
											component={CompanionHorseReport}
											path="/companion-horse-report/:horseCode"
										/>
										<ProtectedRoute
											component={CompanionHorseReport}
											path="/companion-horse-report-by-id/:id"
											rolesRequired={[ROLE_RV_EQUINE_WELFARE]}
										/>

										<NotFoundPage path="/page-not-found" />
									</Router>
								</ErrorBoundary>
							</MainContainer>
						</Store>
					</ThemeProvider>
				</FilePreviewProvider>
			</ToastProvider>
			<Footer />
		</ScreenClassProvider>
	</AuthProvider>
);
export default Index;
