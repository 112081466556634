import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, ScreenClassRender } from 'react-grid-system';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';
import HeaderBanner from '../HeaderBanner';
import InfoCards from '../InfoCards';
import Programs from '../Programs';
import SEO from '../seo';
import useStyles from './styles';

const AcknowledgedRehomersInfo = () => {
	const classes = useStyles();
	const [cards, setCards] = useState([]);
	const [richText, setRichText] = useState(null);

	const renderCollectionOfVideos = (isMobile) => {
		const UniqueVideoByUrl = [
			...new Map(videoCollection.map((item) => [item['src'], item])).values()
		];
		return UniqueVideoByUrl.map((video, index) => {
			return (
				<div
					key={index}
					className={
						isMobile ? classes.mobileVideoContainer : classes.videoContainer
					}
				>
					<div className={classes.videoTitle}>{video.title}</div>
					<FullArticleEmbeddedAsset
						videoStyle={{ borderRadius: 5 }}
						imageStyle={{ borderRadius: 5 }}
						contentType={video.type}
						contentSrc={video.src}
					/>
				</div>
			);
		});
	};

	let videoCollection = [];
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				let video = [];
				video = {
					src: node.data.target.file.url,
					type: node.data.target.file.contentType,
					title: node.data.target.title
				};
				videoCollection.push(video);
			}
		}
	};

	useEffect(() => {
		setRichText(ackRehomersInfo.allContentfulLandingPageContent.nodes[0].body);
	}, []);

	const ackRehomersInfo = useStaticQuery(
		graphql`
			query ackRehomersInfo {
				allContentfulLandingPageContent(
					filter: { tags: { in: "Acknowledged Rehomers Landing Page" } }
				) {
					nodes {
						title {
							title
						}
						body {
							raw
							references {
								__typename
								... on ContentfulAsset {
									contentful_id
									file {
										url
										contentType
										fileName
									}
									title
								}
							}
						}
					}
				}
				allContentfulInfoCards(
					filter: { tags: { in: "Acknowledged Rehomers Info Cards" } }
				) {
					nodes {
						text {
							text
						}
						title
						order
						image {
							file {
								url
								fileName
							}
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		// Set data to state here?
		const ackRetirementProgramCards =
			ackRehomersInfo.allContentfulInfoCards.nodes;
		let ackRetirementInfoCards = [];
		if (ackRetirementProgramCards.length > 0) {
			ackRetirementProgramCards.map((d) => {
				ackRetirementInfoCards.push({
					title: d?.title,
					summary: d?.text?.text,
					image: d?.image?.file?.url,
					order: d?.order
				});
			});
			ackRetirementInfoCards.sort((a, b) =>
				a.order > b.order ? 1 : b.order > a.order ? -1 : 0
			);
			setCards(ackRetirementInfoCards);
		}
	}, []);

	const ackRetirementInformation = (isMobile) => {
		return (
			<>
				<SEO title="Acknowledged Rehomers" />
				<Container
					className={!isMobile ? classes.container : classes.mobileContainer}
				>
					<HeaderBanner
						title="Acknowledged Rehomers"
						styles={{ marginBottom: 0 }}
					/>
				</Container>
				{!isMobile && cards.length > 0 && (
					<InfoCards data={cards} cardStyle={{ width: 320 }} />
				)}
				{isMobile && cards.length > 1 && (
					<Programs data={cards} mobile noReadMore />
				)}
				<Row>
					<Col lg={8}>{richText && renderRichText(richText, options)}</Col>
				</Row>
				<Row>
					<Col>
						<div className={classes.multipleVideos}>
							{renderCollectionOfVideos(isMobile)}
						</div>
					</Col>
				</Row>
			</>
		);
	};

	const mobileComponent = (
		<div style={{ width: '100%' }}>
			<div className={classes.mobileTopContainer}>
				{ackRetirementInformation(true)}
			</div>
		</div>
	);

	const desktopComponent = (
		<>
			<Container className={classes.container}>
				<Row>
					<Col lg={12}>{ackRetirementInformation(false)}</Col>
				</Row>
			</Container>
		</>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default AcknowledgedRehomersInfo;
