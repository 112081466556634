import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FormValidateTextField from '../FormValidateTextField';
import useStyles from './styles';

const FormDatePicker = ({
	id,
	updateSelection,
	isProfile,
	isError,
	DateValue,
	isValidation,
	isValid,
	customDatePicker,
	format,
	noMaxDate,
	canEdit = true,
	isBasic,
	minDate = null,
	showTimeSelect,
	showTimeInput,
	validFoalYear,
	showIcon = true
}) => {
	const classes = useStyles();
	return canEdit == false ? (
		validFoalYear ? (
			<FormValidateTextField
				isValid={isValid}
				value={DateValue}
				canEdit={canEdit}
			/>
		) : (
			<FormValidateTextField
				showIcon={showIcon}
				value={DateValue}
				canEdit={canEdit}
			/>
		)
	) : (
		<div>
			<DatePicker
				id={id}
				showYearDropdown={format !== 'yyyy'}
				showYearPicker={format === 'yyyy'}
				showMonthYearPicker={format === 'MM/yyyy'}
				// showFourColumnMonthYearPicker={format === 'MM/yyyy'}
				dateFormat={
					showTimeInput
						? 'MMMM d, yyyy h:mm aa'
						: format == 'yyyy'
						? 'yyyy'
						: format == 'MM/yyyy'
						? 'MM/yyyy'
						: 'dd/MM/yyyy'
				}
				isClearable
				showTimeSelect={showTimeSelect}
				showTimeInput={showTimeInput}
				clearButtonTitle="clear"
				className={
					customDatePicker ? classes.filterFoalDate : classes.dateField
				}
				wrapperClassName={
					DateValue
						? isValidation
							? isValid
								? classes.dateContainerVerified
								: classes.dateContainerNotVerified
							: classes.dateContainerDefault
						: isProfile
						? classes.dateContainerProfile
						: isBasic
						? classes.dateContainerBasic
						: isError
						? classes.errorDateContainer
						: classes.dateContainer
				}
				autoComplete="off"
				selected={DateValue != null ? new Date(DateValue) : null}
				onChange={(date) => updateSelection(date)}
				maxDate={isBasic || noMaxDate ? '' : new Date()}
				minDate={isBasic || !minDate ? '' : minDate}
				popperClassName={classes.popperClassName}
			/>
		</div>
	);
};

export default FormDatePicker;
