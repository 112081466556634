import React, { useState, useEffect } from 'react';
import PageContainer from '../PageContainer';
import SEO from '../seo';
import { Col } from 'react-grid-system';
import AdminReviewHeader from '../AdminReviewComponents/AdminReviewHeader';
import AdminReviewBody from '../AdminReviewComponents/AdminReviewBody';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getAcknowledgedReTrainerForm,
	getRetrainerAdminActionById,
	getRetrainerLookupsById,
	getRetrainerUploadsById
} from './graphql/queries';
import PageLoadSpinner from '../PageLoadSpinner';
import PersonalDetailsSection from './components/PersonalDetailsSection';
import BusinessDetailsSection from './components/BusinessDetailsSection';
import BusinessSuppliersSection from './components/BusinessSuppliersSection';
import CreditReferencesSection from './components/CreditReferencesSection';
import StaffDetailsSection from './components/StaffDetailsSection';
import InsuranceDetailsSection from './components/InsuranceDetailsSection';
import RetrainingDetailsSection from './components/RetrainingDetailsSection';
import { useParams } from '@reach/router';
import RehomingHistorySection from './components/RehomingHistorySection';
import RetrainingProcessSection from './components/RetrainingProcessSection';
import ExperienceSection from './components/ExperienceSection';
import CurrentLicenseAndRegistrationSection from './components/CurrentLicenseAndRegistrationSection';
import PreviousLicenseAndRegistrationSection from './components/PreviousLicenseAndRegistrationSection';
import DeclarationsSection from './components/DeclarationsSection';
import ApplicationReviewSection from './components/ApplicationReviewSection';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import useFilePreview from '../../hooks/useFilePreview';
import AcknowledgementsSection from './components/AcknowledgementsSection';
import { getAcknowledgedRetirementFormById } from '../../graphql/custom.queries';
import { RETIREMENT, RETRAINER } from '../RetrainerProfileTemplate/constants';

const AcknowledgedRetrainerReview = ({ formType }) => {
	const { id: formId } = useParams();
	const [formData, setFormData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [showPersonalDetailError, setShowPersonalDetailError] = useState(false);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const filePreviewHandler = useFilePreview();
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);

	useEffect(() => {
		fetchForm();
	}, []);

	const fetchForm = async () => {
		try {
			let resultFormData = {};
			const result = await AppSyncService.execute(
				formType === RETIREMENT
					? getAcknowledgedRetirementFormById
					: getAcknowledgedReTrainerForm,
				{ formId }
			);
			const acknowledgedFormData =
				formType === RETIREMENT
					? result?.data?.getAcknowledgedRetirementFormById
					: result?.data?.getAcknowledgedReTrainerFormById;
			if (acknowledgedFormData) resultFormData = acknowledgedFormData;

			if (formType !== RETIREMENT) {
				const adminActionData = await fetchAdminActions();
				const uploadsData = await fetchUploads();
				const lookupData = await fetchLookupData();
				resultFormData = {
					...resultFormData,
					...adminActionData,
					...uploadsData,
					...lookupData
				};
			}

			setFormData(resultFormData);
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchLookupData = async () => {
		try {
			const result = await AppSyncService.execute(getRetrainerLookupsById, {
				formId
			});
			return result?.data?.getAcknowledgedReTrainerFormById ?? {};
		} catch (e) {
			console.error(e);
		}
	};

	const fetchAdminActions = async () => {
		try {
			const result = await AppSyncService.execute(getRetrainerAdminActionById, {
				formId
			});
			return result?.data?.getAcknowledgedReTrainerFormById ?? {};
		} catch (e) {
			console.error(e);
		}
	};

	const fetchUploads = async () => {
		try {
			const result = await AppSyncService.execute(getRetrainerUploadsById, {
				formId
			});
			return result?.data?.getAcknowledgedReTrainerFormById ?? {};
		} catch (e) {
			console.error(e);
		}
	};

	const handleChangeDropdownImage = (e, imageKey) => {
		let tempData = {
			...formData,
			personalDetailUploads: formData.personalDetailUploads.map((item) => {
				if (imageKey === item.key) {
					return {
						...item,
						documentType: isNaN(parseInt(e.target.value))
							? null
							: parseInt(e.target.value)
					};
				} else {
					return item;
				}
			})
		};
		setFormData(tempData);
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	return (
		<PageContainer>
			<SEO
				title={`Acknowledged ${
					formType === RETIREMENT ? RETIREMENT : RETRAINER
				} Application Form - Review`}
			/>
			<PageLoadSpinner isLoading={isLoading}>
				{isLoading ? (
					<></>
				) : (
					<Col>
						<AdminReviewHeader
							title={`Acknowledged ${
								formType === RETIREMENT ? RETIREMENT : RETRAINER
							} - ${formData?.member?.firstName} ${formData?.member?.lastName}`}
							status={formData?.status}
						/>
						<AdminReviewBody>
							<PersonalDetailsSection
								formData={formData}
								showPersonalDetailError={showPersonalDetailError}
								handleChangeDropdownImage={handleChangeDropdownImage}
								previewImage={filePreviewHandler}
							/>
							<BusinessDetailsSection formType={formType} formData={formData} />
							<BusinessSuppliersSection formData={formData} />
							<CreditReferencesSection
								formData={formData}
								previewImage={filePreviewHandler}
							/>
							<StaffDetailsSection formData={formData} />
							<InsuranceDetailsSection
								formData={formData}
								previewImage={filePreviewHandler}
							/>
							{formType === RETRAINER && (
								<>
									<RetrainingDetailsSection formData={formData} />
									<RehomingHistorySection formData={formData} />
									<RetrainingProcessSection
										formData={formData}
										previewImage={filePreviewHandler}
									/>
								</>
							)}
							<ExperienceSection
								formData={formData}
								formType={formType}
								previewImage={filePreviewHandler}
							/>
							<CurrentLicenseAndRegistrationSection formData={formData} />
							<PreviousLicenseAndRegistrationSection formData={formData} />
							<DeclarationsSection formType={formType} formData={formData} />
							<AcknowledgementsSection
								formType={formType}
								formData={formData}
							/>
						</AdminReviewBody>
						<ApplicationReviewSection
							formType={formType}
							formData={formData}
							setShowPersonalDetailError={setShowPersonalDetailError}
							previewImage={filePreviewHandler}
							onImageUploadError={uploadError}
							isReadOnly={
								formData?.status === 'Approved' ||
								formData?.status === 'Rejected'
							}
						/>
					</Col>
				)}

				{showImageUploadErrorModal && (
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
				)}
			</PageLoadSpinner>
		</PageContainer>
	);
};

export default AcknowledgedRetrainerReview;
