import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container, ScreenClassRender, Row, Col } from 'react-grid-system';
import useStyles from './styles';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import InfoCards from '../InfoCards';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';

const MembersAreaLandingPage = () => {
	const classes = useStyles();
	const [cards, setCards] = useState([]);
	const [programDescription, setProgramDescription] = useState(null);
	const [additionalDescription, setAdditionalDescription] = useState(null);
	const [programTitle, setProgramTitle] = useState('');

	const programInfo = useStaticQuery(
		graphql`
			query membersAreaQuery {
				allContentfulLandingPageContent(
					filter: { tags: { in: "Members Area Landing Page" } }
				) {
					nodes {
						title {
							title
						}
						body {
							raw
							references {
								__typename
								... on ContentfulAsset {
									contentful_id
									file {
										url
										contentType
										fileName
									}
									title
								}
							}
						}
						additionalBody {
							raw
							references {
								__typename
								... on ContentfulAsset {
									contentful_id
									file {
										url
										contentType
										fileName
									}
									title
								}
							}
						}
					}
				}
				allContentfulInfoCards(
					filter: { tags: { in: "Members Area Info Cards" } }
				) {
					nodes {
						text {
							text
						}
						title
						order
						link
						image {
							file {
								url
								fileName
							}
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		setProgramDescription(
			programInfo.allContentfulLandingPageContent.nodes[0]?.body
		);
		setAdditionalDescription(
			programInfo.allContentfulLandingPageContent.nodes[0]?.additionalBody
		);
		setProgramTitle(
			programInfo.allContentfulLandingPageContent.nodes[0].title?.title
		);

		const programInfoCards = programInfo.allContentfulInfoCards.nodes;
		let OttProgramInfoCards = [];
		if (programInfoCards.length > 0) {
			programInfoCards.map((d) => {
				OttProgramInfoCards.push({
					title: d?.title,
					summary: d?.text?.text,
					image: d?.image?.file?.url,
					order: d?.order,
					link: d?.link
				});
			});
			OttProgramInfoCards.sort((a, b) =>
				a.order > b.order ? 1 : b.order > a.order ? -1 : 0
			);
			setCards(OttProgramInfoCards);
		}
	}, [programInfo]);

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			)
		}
	};

	const programInformation = (isMobile) => {
		return (
			<>
				<SEO title="Members Area" />
				<Container
					className={!isMobile ? classes.container : classes.mobileContainer}
				>
					<HeaderBanner title="Members Area" styles={{ marginBottom: 0 }} />
				</Container>
				{
					<>
						<div
							className={isMobile ? classes.textTitleMobile : classes.textTitle}
						>
							{programTitle}
						</div>
						<div
							className={
								isMobile
									? classes.textDescriptionMobile
									: classes.textDescription
							}
						>
							{programDescription &&
								renderRichText(programDescription, options)}
						</div>
					</>
				}
				{cards.length > 0 && (
					<InfoCards data={cards} cardStyle={{ width: 320 }} />
				)}
				<div
					className={
						isMobile ? classes.textDescriptionMobile : classes.textDescription
					}
				>
					{additionalDescription &&
						renderRichText(additionalDescription, options)}
				</div>
			</>
		);
	};

	const mobileComponent = (
		<div style={{ width: '100%' }}>
			<div className={classes.mobileTopContainer}>
				{programInformation(true)}
			</div>
		</div>
	);

	const desktopComponent = (
		<>
			<Container className={classes.container}>
				<Row>
					<Col lg={12}>{programInformation(false)}</Col>
				</Row>
			</Container>
		</>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default MembersAreaLandingPage;
