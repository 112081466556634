export const getAcknowledgedReTrainerForm = /* GraphQL */ `
	query getAcknowledgedRetrainerForm($formId: Int!) {
		getAcknowledgedReTrainerFormById(id: $formId) {
			id
			acknowledgesEquineWelfare
			acknowledgesNationalPoliceRecordCertificate
			acknowledgesCriminalCharges
			acknowledgesBankruptcy
			acknowledgesAgreement
			acknowledgesToUtiliseOttCommunity
			acknowledgesCorrectApplicationDetails
			stableAddressSameAsResidental
			stableCountry
			stablePostcode
			stableSuburb
			stableState
			stableAddress
			residentalAddress
			residentalCountry
			residentalPostcode
			residentalState
			residentalSuburb
			agentName
			status
			businessName
			abn
			businessNameOperation
			additionalEmploymentDetails
			otherActivitiesDetails
			veterinaryPracticeName
			veterinaryAddress
			veterinaryPhone
			veterinaryEmail
			regularVeterinarian
			farrier
			farrierEmail
			farrierPhone
			liabilityPolicyNumber
			liabilityCoverage
			liabilityExpiryDate
			publicLiabilityInsurerName
			publicLiabilityInsuredPartyName
			workersCompensationCoverage
			workersCompensationExpiryDate
			workersCompensationPolicyNumber
			workersCompensationInsuredPartyName
			indemnityInsurerName
			indemnityInsuredPartyName
			indemnityPolicyNumber
			indemnityExpiryDate
			indemnityCoverage
			professionalIndemnity
			sourceOfHorsesOther
			disciplinesOther
			rehomedHorsesDetails
			confirmCapacityToRetrain
			reasonToBecomeAckRetrainer
			expectations
			retrainingProcess
			stepsTakenForLongTermHorsePlacement
			raceNames
			manageUnsuitableDetails
			businessModel
			canReceiveEmergencyCases
			ridingExperience
			membershipsOther
			coach
			qualifyToCoach
			qualifications
			videoDetails
			licence
			licenceStart
			previousLicence
			previousLicenceStart
			hadRSPCAInvestigation
			rspcaInvestigationDetails
			isBannedByAnyRacingAuthority
			bannedByAnyRacingAuthorityDetails
			disqualified
			disqualificationDetails
			wasCharged
			chargeDetails
			currentCriminalProceedings
			criminalProceedingDetails
			bankruptcy
			bankruptcyDetails
			postalAddress
			postalCountry
			postalPostcode
			postalState
			postalSuburb
			additionalDetails
			trusteeName
			trusteeAcn
			trusteeHasShares
			companySharesOwnershipDetails
			trusteeFurtherDetails
			ownsAllCompanyShares
			companySharesOwnershipDetails
			videoAssessmentComments
			videoAssessmentDate
			phoneInterviewComments
			phoneInterviewDate
			propertyAssessmentComments
			propertyAssessmentDate
			ridingAssessmentComments
			ridingAssessmentdate
			ridingScore
			ewgmComment
			ewgmReviewDate
			applicationRecommendation
			propertyRented
			contactNumber
		}
	}
`;

export const getRetrainerLookupsById = /* GraphQL */ `
	query getRetrainerLookupsById($formId: Int!) {
		getAcknowledgedReTrainerFormById(id: $formId) {
			member {
				email
				firstName
				gender
				lastName
				dateOfBirth
				phone
				postalAddress {
					address
					country
					postcode
					state
					suburb
				}
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			additionalEmployment {
				id
				value
			}
			staffs {
				fullName
				role {
					value
				}
				id
			}
			sourceOfHorses {
				value
			}
			disciplines {
				value
			}
			numberOfHorses {
				value
			}
			memberships {
				value
			}
			licenceType {
				value
			}
			rejectionReasons {
				id
				value
			}
			partners {
				contactDetails
				name
			}
			businessType {
				id
				value
			}
			previousLicenceType {
				value
			}
		}
	}
`;

export const getRetrainerUploadsById = /* GraphQL */ `
	query getRetrainerUploadsById($formId: Int!) {
		getAcknowledgedReTrainerFormById(id: $formId) {
			resume {
				filename
				key
			}
			personalDetailUploads {
				filename
				key
				documentType
				type
				sightedBy {
					firstName
					lastName
					id
				}
				isDeleted
				dateApprovedRejected
				status
			}
			insuranceCertificateOfCurrencyUploads {
				filename
				key
				sightedBy {
					firstName
					lastName
					id
				}
				isDeleted
				dateApprovedRejected
				status
			}
			creditReferencesUploads {
				filename
				key
			}
			signedReferences {
				filename
				key
			}
			ridingAssessmentUpload {
				filename
				key
			}
			propertyRidingPhotos {
				filename
				key
			}
			propertyAssessmentUploads {
				filename
				key
			}
			videoAssessmentUploads {
				filename
				key
			}
			certificates {
				filename
				key
			}
		}
	}
`;

export const getRetrainerAdminActionById = /* GraphQL */ `
	query getRetrainerAdminActionById($formId: Int!) {
		getAcknowledgedReTrainerFormById(id: $formId) {
			adminAction {
				admin {
					externalId
				}
				adminStatus {
					id
				}
				formComplete
			}
		}
	}
`;

export const getApplicationReviewLookupValues = `
  query getApplicationReviewLookupValues {
    getAdminMembers {
      id
      externalId
      firstName
      lastName
    }
    listRetrainerFormAdminActions {
      id
      value
    }
    listRetrainerFormRejectReasons {
      id
      value
    }
	listRetirementFormRejectReasons {
		id
		value
	}
  }
`;
