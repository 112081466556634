import React from 'react';
import FormTextField from '../FormTextField';
import useStyles from './styles';

const FormValidateTextField = ({
	id,
	onChange,
	styles,
	value,
	isValid,
	canEdit = true,
	showIcon = true
}) => {
	const classes = useStyles();
	return (
		<div
			className={
				showIcon
					? `${classes.fieldIcon} ${
							canEdit == true ? classes.fieldEditable : classes.fieldNonEditable
					  }
        ${
					isValid && isValid != 'default' && (value != '' || value != null)
						? classes.fieldValid
						: value == '' || value === null
						? ''
						: classes.fieldNotValid
				}
      `
					: ''
			}
		>
			<FormTextField
				id={id}
				onChange={onChange}
				value={value ? value : ''}
				style={{ styles }}
				canEdit={canEdit}
			/>
		</div>
	);
};

export default FormValidateTextField;
