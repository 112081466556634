import React from 'react';
import InfoModal from '../InfoModal';

const modalStyles = {
	content: {
		maxWidth: 500,
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

const UploadPhotoErrorModal = ({
	showErrorModal,
	closeModal,
	isMinimumUploadSize,
	maxTitle = '5'
}) => {
	const heading = `${
		isMinimumUploadSize ? 'Invalid file size' : ' Upload File Error'
	}`;
	const bodyContent = isMinimumUploadSize ? (
		`File's size need to be atleast 1KB. Please upload again.`
	) : (
		<>
			<p>This file upload has failed.</p>
			<p>
				The most common cause is the file size being too large, or file type.
				Try taking a screenshot of the file or image, or resizing the image to
				be less than {maxTitle}MB.
			</p>
		</>
	);
	return (
		<InfoModal
			showModal={showErrorModal}
			closeModal={closeModal}
			styles={modalStyles}
			heading={heading}
			bodyContent={bodyContent}
		/>
	);
};

export default UploadPhotoErrorModal;
