import { navigate, useParams } from '@reach/router';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row, useScreenClass } from 'react-grid-system';
import Lightbox from 'react-image-lightbox';
import AppSyncService from '../../graphql/AppSyncService';
import {
	deleteCompanionHorseReport,
	deleteImageFromS3,
	deleteImagesFromS3,
	getCompanionHorseReport,
	getCompanionHorseReportById,
	getHorses,
	getMemberData,
	getTransitionReportReferences,
	getUserImage,
	saveCompanionHorseReport,
	saveResetReportingImage,
	uploadImageToS3
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import {
	APPROVED,
	CommonKeys,
	DRAFT,
	HorseManagementKeys,
	REJECTED,
	REVIEW,
	ROLE_ID
} from '../../utils/constants';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import {
	checkIsSmallScreen,
	checkUserAdmin,
	extractLookUpOptionsOther,
	formatDate
} from '../../utils/helpers';
import ConfirmationModal from '../ConfirmationModal';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import FormDatePicker from '../FormDatePicker';
import FormDropDown from '../FormDropDown';
import FormLabelField from '../FormLabelField';
import HeaderBanner from '../HeaderBanner';
import PageContainer from '../PageContainer';
import PrimaryButton from '../PrimaryButton';
import ResizingTextArea from '../ResizingTextArea';
import SEO from '../seo';
import SubmitOnBehalf from '../SubmitOnBehalf';
import Toggle from '../Toggle';
import UploadPhoto from '../UploadPhoto';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import { ANNUAL_UPDATE, FORM_CAUSE_OPTIONS, NEW_ARRIVAL } from './constants';
import useStyles from './styles';
import { extractValidationErrors, isOtherSelectedMulti } from './utils';
import AdminApprovalOnly from '../AdminApprovalOnly';

const CompanionHorseReport = () => {
	const classes = useStyles();
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const { horseCode, id } = useParams();
	const [horse, setHorse] = useState();
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [isCancelled, setIsCancelled] = useState(false);
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [reviewNotesData, setReviewNotesData] = useState('');
	const [reviewStatus, setReviewStatus] = useState('');
	const [currentUserMemberId, setCurrentUserMemberId] = useState(null);
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [shouldValidate, setShouldValidate] = useState(false);
	const [isSubmitOnBehalf, setIsSubmitOnBehalf] = useState(false);
	const [selectedUserVerifiedInfo, setSelectedUserVerifiedInfo] = useState(
		null
	);
	const [isSelectUserRequired, setIsSelectUserRequired] = useState(false);
	const [selectUserRequiredError, setSelectUserRequiredError] = useState('');
	const { addToast } = useToast();
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [disableSubmitButton, setDisableSubmitbutton] = useState(false);

	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);

	const [formCause, setFormCause] = useState(null);
	const [assessmentDate, setAssessmentDate] = useState(new Date());
	const [arrivalDate, setArrivalDate] = useState(null);
	const [knownAsName, setKnownAsName] = useState(null);
	const [explainSettlingPeriod, setExplainSettlingPeriod] = useState(null);
	const [describeHorseCondition, setDescribeHorseCondition] = useState(null);
	const [isWorming, setIsWorming] = useState(true);
	const [wormingDate, setWormingDate] = useState(new Date());
	const [isFarrier, setIsFarrier] = useState(true);
	const [farrierDate, setFarrierDate] = useState(new Date());
	const [isDentist, setIsDentist] = useState(true);
	const [dentistDate, setDentistDate] = useState(new Date());
	const [isVet, setIsVet] = useState(true);
	const [vetDate, setVetDate] = useState(new Date());
	const [vetVisitDetails, setVetVisitDetails] = useState(null);
	const [husbandryCare, setHusbandryCare] = useState(null);
	const [horseFeedDetails, setHorseFeedDetails] = useState(null);
	const [horseTemperamentDesc, setHorseTemperamentDesc] = useState(null);
	const [horseInteractionDesc, setHorseInteractionDesc] = useState(null);
	const [anyReports, setAnyReports] = useState(null);
	const [habitOtherDetails, setHabitOtherDetails] = useState(null);
	const [habits, setHabits] = useState([]);
	const [photosOfTheHorse, setPhotosOfTheHorse] = useState([]);
	const [reportId, setReportId] = useState(null);
	// const [formStatus, setFormStatus] = useState(null);
	const [vicesOptions, setVicesOptions] = useState([]);
	const { currentUser } = useAuth();
	const isAdminUser = checkUserAdmin(currentUser?.Roles);

	const modalStylesConfirmation = {
		content: {
			minWidth: 300,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};

	const fields = {
		formCause,
		assessmentDate,
		arrivalDate,
		knownAsName,
		explainSettlingPeriod,
		describeHorseCondition,
		isWorming,
		wormingDate,
		isFarrier,
		farrierDate,
		isDentist,
		dentistDate,
		isVet,
		vetDate,
		vetVisitDetails,
		husbandryCare,
		horseFeedDetails,
		horseTemperamentDesc,
		horseInteractionDesc,
		anyReports,
		habitOtherDetails,
		habits,
		photosOfTheHorse
	};

	const validateFields = () => {
		let errors = extractValidationErrors(fields);

		if (errors) {
			setDisableSubmitbutton(true);
		}
		setUncontrolledErrors(errors);
		return errors;
	};

	useEffect(() => {
		fetchMemberData();
	}, [currentUser]);

	const fetchMemberData = async () => {
		if (currentUser?.UserId) {
			const result = await AppSyncService.execute(getMemberData, {
				externalId: currentUser.UserId
			});

			if (result?.data?.getMember) {
				setCurrentUserMemberId(result.data.getMember.id);
			}
		}
	};

	const onSelectUserVerified = (selectedUserVerified) => {
		if (selectedUserVerified?.id !== selectedUserVerifiedInfo?.id) {
			onClearFields();
		}
		setIsSelectUserRequired(false);
		setSelectedUserVerifiedInfo(selectedUserVerified);
	};

	const handleSave = async (isSubmit = false) => {
		const filteredHabits = habits.map((habit) => habit.value);
		const payload = {
			id: reportId ?? null,
			memberId: currentUserMemberId,
			horseCode: +horseCode,

			formCause,
			assessmentDate,
			arrivalDate,
			knownAsName,
			explainSettlingPeriod,
			describeHorseCondition,
			isWorming,
			wormingDate,
			isFarrier,
			farrierDate,
			isDentist,
			dentistDate,
			isVet,
			vetDate,
			vetVisitDetails,
			husbandryCare,
			horseFeedDetails,
			horseTemperamentDesc,
			horseInteractionDesc,
			anyReports,
			habitOtherDetails,
			habits: filteredHabits,
			status: isSubmit ? REVIEW : DRAFT
		};

		const data = await AppSyncService.execute(saveCompanionHorseReport, {
			input: payload
		});
		if (data?.data?.saveCompanionHorseReport?.success) {
			let photoArrays = [{ name: 'horsePhotos', arr: photosOfTheHorse }];
			photoArrays.forEach((photoArray, index) => {
				photoArray.arr.map((photo) => {
					AppSyncService.execute(saveResetReportingImage, {
						filename: photo.filename,
						key: photo.key,
						reportType: 'CompanionHorseReport',
						reportId: data.data.saveCompanionHorseReport.id,
						type: photoArrays[index].name
					});
				});
			});
			setReportId(data.data.saveCompanionHorseReport.id)
			if (isSubmit) {
				addToast({
					Message: 'Companion Horse Report Submitted',
					IsSuccess: true
				});
				navigate(DASHBOARD_ROUTE);
			} else
				addToast({ Message: 'Companion Horse Report Saved', IsSuccess: true });
			setReportId(data.data.saveCompanionHorseReport.id);
		} else
			addToast({
				Message: `Failed to ${
					isSubmit ? 'submit' : 'save'
				} Companion Horse Report`,
				IsSuccess: false
			});
	};

	const handleOnSubmit = () => {
		const errors = Object.keys(validateFields());
		if (errors?.length > 0) {
			setDisableSubmitbutton(true);
			setIsShowErrorMessages(true);
		} else {
			handleSave(true);
		}
	};

	useEffect(() => {
		if (!id) {
			AppSyncService.execute(getHorses, { horseCode: +horseCode }).then(
				(result) => {
					if (result?.data?.getHorse) {
						setHorse(result.data.getHorse);
					}
				}
			);
		}
	}, []);

	useEffect(() => {
		if (isSelectUserRequired) {
			setSelectUserRequiredError('User is required');
		} else {
			setSelectUserRequiredError('');
		}
	}, [isSelectUserRequired]);

	useEffect(() => {
		if (shouldValidate) {
			const errors = Object.keys(validateFields());
			if (errors?.length > 0) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
			setShouldValidate(false);
		}
	}, [shouldValidate]);

	useEffect(() => {
		if (currentUser !== null) {
			AppSyncService.execute(getTransitionReportReferences).then((result) => {
				if (result.data) {
					setVicesOptions(
						extractLookUpOptionsOther(result, 'listHabitsAndVices')
					);
				}
			});
		}
	}, []);

	useEffect(() => {
		if (currentUser !== null) {
			fetchCompanionHorseReport();
		}
	}, [currentUser]);

	const fetchCompanionHorseReport = () => {
		let query = getCompanionHorseReport;
		let queryParams = {
			horseCode: +horseCode,
			memberExternalId: currentUser.UserId
		};
		if (id && isAdminUser) {
			query = getCompanionHorseReportById;
			queryParams = { id: +id };
		}
		AppSyncService.execute(query, queryParams).then((result) => {
			if (result.data) {
				const initialData =
					id && isAdminUser
						? result?.data?.getCompanionHorseReportById
						: result?.data?.getCompanionHorseReport;

				if (initialData?.status == null) {
					setIsShowErrorMessages(false);
				} else {
					setIsShowErrorMessages(true);
				}
				if (id && isAdminUser) {
					setHorse(initialData.horse);
				}
				setReviewStatus(initialData?.status);
				setReviewNotesData(initialData?.reviewNotes ?? '');
				setReportId(initialData?.id ?? null);
				setFormCause(initialData?.formCause ?? '');
				setAssessmentDate(initialData?.assessmentDate ?? null);
				setArrivalDate(initialData?.arrivalDate ?? null);
				setKnownAsName(initialData?.knownAsName ?? '');
				setExplainSettlingPeriod(initialData?.explainSettlingPeriod ?? '');
				setDescribeHorseCondition(initialData?.describeHorseCondition ?? '');
				setIsWorming(initialData?.isWorming ?? false);
				setWormingDate(initialData?.wormingDate ?? null);
				setIsFarrier(initialData?.isFarrier ?? false);
				setFarrierDate(initialData?.farrierDate ?? null);
				setIsDentist(initialData?.isDentist ?? false);
				setDentistDate(initialData?.dentistDate ?? null);
				setIsVet(initialData?.isVet ?? false);
				setVetDate(initialData?.vetDate ?? null);
				setVetVisitDetails(initialData?.vetVisitDetails ?? '');
				setHusbandryCare(initialData?.husbandryCare ?? '');
				setHorseFeedDetails(initialData?.horseFeedDetails ?? '');
				setHorseTemperamentDesc(initialData?.horseTemperamentDesc ?? '');
				setHorseInteractionDesc(initialData?.horseInteractionDesc ?? '');
				setAnyReports(initialData?.anyReports ?? '');
				if (initialData?.habits.length > 0) {
					const habits = initialData?.habits.map((habit) => ({
						value: habit.id,
						label: habit.value
					}));
					setHabits(habits);
				}
				setHabitOtherDetails(initialData?.habitOtherDetails ?? '');
				setPhotosOfTheHorse(initialData?.photosOfTheHorse ?? []);

				setIsReadOnly(
					id ||
						initialData?.status === REVIEW ||
						initialData?.status === APPROVED
				);

				if (initialData?.status === DRAFT || initialData?.status === REJECTED) {
					setDisableSubmitbutton(false);
					setShouldValidate(true);
				}
			}
		});
	};

	const updateDate = (value, stateAssignmentFunction) => {
		if (!value) stateAssignmentFunction(null);
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			stateAssignmentFunction(formattedDate);
		} else {
			stateAssignmentFunction(null);
		}
	};

	const onClearFields = () => {
		setFormCause(null);
		setAssessmentDate(new Date());
		setArrivalDate(null);
		setKnownAsName('');
		setExplainSettlingPeriod('');
		setDescribeHorseCondition('');
		setIsWorming(true);
		setWormingDate(new Date());
		setIsFarrier(true);
		setFarrierDate(new Date());
		setIsDentist(true);
		setDentistDate(new Date());
		setIsVet(true);
		setVetDate(new Date());
		setVetVisitDetails('');
		setHusbandryCare('');
		setHorseFeedDetails('');
		setHorseTemperamentDesc('');
		setHorseInteractionDesc('');
		setAnyReports('');
		setHabits([]);
		setHabitOtherDetails('');
		setPhotosOfTheHorse([]);
		setIsShowErrorMessages(false);
		setUncontrolledErrors({});
	};

	const onToggleSubmitBehalf = (submitOnBehalf) => {
		setIsSubmitOnBehalf(submitOnBehalf);
		onClearFields();
		if (!submitOnBehalf) {
			fetchCompanionHorseReport();
		}
	};

	const navigateToDashboard = () => {
		navigate(DASHBOARD_ROUTE);
	};

	const onCancelSuccess = () => {
		setIsCancelled(true);
		addToast({ Message: 'Request cancelled', IsSuccess: true });
		setConfirmationModal(false);
		navigateToDashboard();
	};

	const removeHorseCompanionReport = () => {
		AppSyncService.execute(deleteCompanionHorseReport, {
			id: reportId
		}).then((data) => {
			if (data.data != null) {
				onCancelSuccess();
			} else {
				console.error(data);
				addToast({
					Message:
						'Unknown error while cancelling Horse Companion Report data. Please contact support',
					IsSuccess: false
				});
			}
		});
	};

	const cancelRequest = () => {
		// if (formStatus && formStatus === 'Approved') {
		// 	onCancelSuccess();
		// 	return;
		// }
		deleteAllPhotos();
		removeHorseCompanionReport();
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const photosUpload = (image, photoArraySetter) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						photoArraySetter((photoArray) => [
							...photoArray,
							{ filename: image.name, key: imageKey }
						]);
						setShouldValidate(true);
					});
			}
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key !== img.key));
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
				setShouldValidate(true);
			}
		);
	};

	const deleteAllPhotos = async () => {
		let listOfKeys = [];
		[photosOfTheHorse].forEach((photoArray) => {
			photoArray.map((d) => {
				listOfKeys.push(d.key);
			});
		});
		if (listOfKeys.length > 0) {
			const result = await AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			});
			if (result?.data?.deleteUserImages?.success) {
				setPhotosOfTheHorse([]);
			}
		}
	};

	return (
		<>
			<PageContainer>
				<SEO title="Retired Thoroughbred Companion (RTC) Horse Welfare Report" />
				{isAdminUser && !isReadOnly ? (
					<SubmitOnBehalf
						typeOfUsers={ROLE_ID.ACK_REHOMER}
						horseCode={horseCode}
						onToggleSubmitBehalf={onToggleSubmitBehalf}
						onSelectUserVerified={onSelectUserVerified}
						isGetAllUsersVerified={false}
						selectUserRequiredError={selectUserRequiredError}
						isSelectUserRequired={isSelectUserRequired}
					/>
				) : null}
				<div
					style={
						isSmallScreen
							? { border: 'none' }
							: {
									borderTop: '1px solid #b3bcc1',
									borderRadius: '5px 5px 0px 0px',
									borderBottom: 'none'
							  }
					}
					className={
						isSmallScreen
							? classes.sectionContainerMobile
							: classes.sectionContainer
					}
				>
					<HeaderBanner
						title="Retired Thoroughbred Companion (RTC) Horse Welfare Report"
						mobile={isSmallScreen}
						styles={{
							marginBottom: 0,
							marginTop: 0,
							borderRadius: '5px 5px 0px 0px'
						}}
					/>
					<HeaderBanner
						title="Horse Details"
						mobile={isSmallScreen}
						type="info"
						styles={{ marginBottom: 0, marginTop: 0, borderRadius: '0px' }}
					/>
					<div
						className={
							isSmallScreen
								? classes.sectionContainerMobile
								: classes.sectionContainer
						}
						style={{ backgroundColor: '#f0f0f0' }}
					>
						<Row className={classes.d}>
							<Col xs={12} lg={4}>
								<FormLabelField title="Name" value={horse?.name} />
							</Col>
							<Col xs={12} lg={4}>
								<FormLabelField title="Colour" value={horse?.colour} />
							</Col>
							<Col xs={12} lg={4}>
								<FormLabelField title="Sex" value={horse?.sex} />
							</Col>
						</Row>
						<Row className={classes.d}>
							<Col xs={12} lg={4}>
								<FormLabelField title="Age" value={horse?.age} />
							</Col>
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Microchip Number"
									value={horse?.microChipNumber}
								/>
							</Col>
							<Col xs={12} lg={4}>
								<FormLabelField title="Status" value={horse?.status} />
							</Col>
						</Row>
					</div>
					<Row className={classes.rowNoMargin}>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Are you completing this form for a new arrival, or an annual
									update?*
								</div>
								<FormDropDown
									showBlank
									id={'formCause'}
									items={FORM_CAUSE_OPTIONS}
									selectedId={formCause}
									onChange={(e) => {
										setFormCause(e.target.value);
										setShouldValidate(true);
									}}
									disabled={isReadOnly}
								/>
							</div>
						</Col>
						<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Assessment date*</div>
								<FormDatePicker
									id="assessment_date"
									DateValue={assessmentDate}
									updateSelection={(value) => {
										updateDate(value, setAssessmentDate);
										setShouldValidate(true);
									}}
									canEdit={!isReadOnly}
									showIcon={false}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.assessmentDate?.message}
									</span>
								)}
							</div>
						</Col>
						{formCause !== ANNUAL_UPDATE && (
							<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Arrival date*</div>
									<FormDatePicker
										id="arrival_date"
										DateValue={arrivalDate}
										updateSelection={(value) => {
											updateDate(value, setArrivalDate);
											setShouldValidate(true);
										}}
										canEdit={!isReadOnly}
										showIcon={false}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.arrivalDate?.message}
										</span>
									)}
								</div>
							</Col>
						)}
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={1}
										isShowRemainingChars={false}
										name="knownAsName"
										title="Known as name*"
										id="chr-knownAsName"
										value={knownAsName}
										onChange={(e) => {
											setKnownAsName(e.target.value);
											setShouldValidate(true);
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.knownAsName?.message}
									</span>
								)}
							</div>
						</Col>
						<Col xs={12} lg={6}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Photos of your horse*</div>
								<UploadPhoto
									id={`chr-photos`}
									formats={['png', 'jpeg']}
									labelName="Upload photos of your horse*"
									error={uploadError}
									data={photosOfTheHorse}
									sizeLimitMB={8}
									numberOfImages={5}
									success={(e) => photosUpload(e, setPhotosOfTheHorse)}
									getImage={getImage}
									removeImage={(e) =>
										removePhoto(e, photosOfTheHorse, setPhotosOfTheHorse)
									}
									canEdit={!isReadOnly}
								/>
							</div>
							<Row className={classes.rowNoMargin}>
								<span style={{ fontStyle: 'italic' }}>
									Please ensure clear photos, including a full body from each
									side, taken on or as close as possible to this assessment
									being completed.
								</span>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors?.photosOfTheHorse?.message}
									</span>
								)}
							</Row>
						</Col>
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										name="explainSettlingPeriod"
										title={`Please briefly explain what the settling in period has looked like for you and your horse${
											formCause == NEW_ARRIVAL ? '*' : ''
										}`}
										id="chr-explainSettlingPeriod"
										value={explainSettlingPeriod}
										onChange={(e) => {
											setExplainSettlingPeriod(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={2000}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.explainSettlingPeriod?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										name="describeHorseCondition"
										title={`Describe the overall condition of your horse*`}
										id="chr-describeHorseCondition"
										value={describeHorseCondition}
										onChange={(e) => {
											setDescribeHorseCondition(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={2000}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.describeHorseCondition?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Worming</div>
								<Toggle
									id={'isWorming'}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setIsWorming(value);
										setShouldValidate(true);
									}}
									value={isWorming}
									disabled={isReadOnly}
								/>
							</div>
						</Col>
						{isWorming && (
							<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Date*</div>
									<FormDatePicker
										id="worming_date"
										noMaxDate
										DateValue={wormingDate}
										updateSelection={(value) => {
											updateDate(value, setWormingDate);
											setShouldValidate(true);
										}}
										canEdit={!isReadOnly}
										showIcon={false}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.wormingDate?.message}
										</span>
									)}
								</div>
							</Col>
						)}
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Farrier</div>
								<Toggle
									id={'isFarrier'}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setIsFarrier(value);
										setShouldValidate(true);
									}}
									value={isFarrier}
									disabled={isReadOnly}
								/>
							</div>
						</Col>
						{isFarrier && (
							<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Date*</div>
									<FormDatePicker
										id="farrier_date"
										noMaxDate
										DateValue={farrierDate}
										updateSelection={(value) => {
											updateDate(value, setFarrierDate);
											setShouldValidate(true);
										}}
										canEdit={!isReadOnly}
										showIcon={false}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.farrierDate?.message}
										</span>
									)}
								</div>
							</Col>
						)}
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Dentist</div>
								<Toggle
									id={'isDentist'}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setIsDentist(value);
										setShouldValidate(true);
									}}
									value={isDentist}
									disabled={isReadOnly}
								/>
							</div>
						</Col>
						{isDentist && (
							<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Date*</div>
									<FormDatePicker
										id="dentist_date"
										noMaxDate
										DateValue={dentistDate}
										updateSelection={(value) => {
											updateDate(value, setDentistDate);
											setShouldValidate(true);
										}}
										canEdit={!isReadOnly}
										showIcon={false}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.dentistDate?.message}
										</span>
									)}
								</div>
							</Col>
						)}
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Vet</div>
								<Toggle
									id={'isVet'}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setIsVet(value);
										setShouldValidate(true);
									}}
									value={isVet}
									disabled={isReadOnly}
								/>
							</div>
						</Col>
						{isVet && (
							<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Date*</div>
									<FormDatePicker
										id="vet_date"
										noMaxDate
										DateValue={vetDate}
										updateSelection={(value) => {
											updateDate(value, setVetDate);
											setShouldValidate(true);
										}}
										canEdit={!isReadOnly}
										showIcon={false}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.vetDate?.message}
										</span>
									)}
								</div>
							</Col>
						)}
					</Row>
					{isVet && (
						<Row className={classes.rowNoMargin}>
							<Col xs={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										<ResizingTextArea
											rows={2}
											name="vetVisitDetails"
											title={`Vet visit details*`}
											id="chr-vetVisitDetails"
											value={vetVisitDetails}
											onChange={(e) => {
												setVetVisitDetails(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={2000}
											charLengthStyle={{
												fontWeight: 'normal'
											}}
											styles={{
												marginLeft: 0,
												marginRight: 0,
												padding: 0
											}}
											titleStyles={{ fontSize: '14px' }}
											canEdit={!isReadOnly}
										/>
									</div>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.vetVisitDetails?.message}
										</span>
									)}
								</div>
							</Col>
						</Row>
					)}
					<Row className={classes.rowNoMargin}>
						<Col xs={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										name="husbandryCare"
										title={`Other husbandry care required or planned?`}
										id="chr-husbandryCare"
										value={husbandryCare}
										onChange={(e) => {
											setHusbandryCare(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={2000}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.husbandryCare?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										name="horseFeedDetails"
										title={`What are you currently feeding your horse and how often?*`}
										id="chr-horseFeedDetails"
										value={horseFeedDetails}
										onChange={(e) => {
											setHorseFeedDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={2000}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.horseFeedDetails?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										name="horseTemperamentDesc"
										title={`Your assessment of your horse's temperament and general behaviour*`}
										id="chr-horseTemperamentDesc"
										value={horseTemperamentDesc}
										onChange={(e) => {
											setHorseTemperamentDesc(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={2000}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.horseTemperamentDesc?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										name="horseInteractionDesc"
										title={`Your assessment of your horse’s interactions with your other horses*`}
										id="chr-horseInteractionDesc"
										value={horseInteractionDesc}
										onChange={(e) => {
											setHorseInteractionDesc(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={2000}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.horseInteractionDesc?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12} lg={4}>
							{isReadOnly ? (
								<FormLabelField
									title={`Any vices being displayed?`}
									value={habits.map((g) => g.label).join()}
									style={{ padding: 0 }}
								/>
							) : (
								<div className={classes.fieldContainer}>
									<MultiSelectInputField
										noMargin
										idPrefix={`habits`}
										fieldLabel={`Any vices being displayed?`}
										options={vicesOptions}
										selectedIds={habits}
										onSelectChange={(vals) => {
											setHabits(vals);
											setShouldValidate(true);
										}}
										otherDisabled={true}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.habits?.message}
										</span>
									)}
								</div>
							)}
						</Col>
						{isOtherSelectedMulti(habits) && (
							<Col xs={12} lg={8}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										<ResizingTextArea
											rows={1}
											isShowRemainingChars={false}
											name="habitOtherDetails"
											title="Other details*"
											id="chr-habitOtherDetails"
											value={habitOtherDetails}
											onChange={(e) => {
												setHabitOtherDetails(e.target.value);
												setShouldValidate(true);
											}}
											charLengthStyle={{
												fontWeight: 'normal'
											}}
											styles={{
												marginLeft: 0,
												marginRight: 0,
												padding: 0
											}}
											titleStyles={{ fontSize: '14px' }}
											canEdit={!isReadOnly}
										/>
									</div>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.habitOtherDetails?.message}
										</span>
									)}
								</div>
							</Col>
						)}
					</Row>
					<Row className={classes.rowNoMargin}>
						<Col xs={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										name="anyReports"
										title={`If you have anything else to report relating to the horse, please let us know.`}
										id="chr-anyReports"
										value={anyReports}
										onChange={(e) => {
											setAnyReports(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={2000}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.anyReports?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
					{id && isAdminUser ? (
						<AdminApprovalOnly
							reviewNotesData={reviewNotesData}
							id={id}
							reviewStatus={reviewStatus}
							reviewType="CompanionHorseReport"
						/>
					) : (
						<div className={classes.submitContainer}>
							<span
								className={classes.cancelRequest}
								style={isCancelled ? { display: 'none' } : {}}
								onClick={() => setConfirmationModal(true)}
							>
								Cancel
							</span>
							<div
								className={
									isSmallScreen
										? classes.saveRequestMobile
										: classes.saveRequest
								}
							>
								<span className={isSmallScreen ? null : classes.saveButton}>
									<PrimaryButton
										id={CommonKeys.SAVE}
										isNonSubmit={false}
										style={{ minWidth: '170px' }}
										onClick={() => handleSave()}
										disabled={
											isReadOnly ||
											(isSubmitOnBehalf && !selectedUserVerifiedInfo)
										}
									>
										Save Draft
									</PrimaryButton>
								</span>
								<span>
									<PrimaryButton
										id={HorseManagementKeys.SUBMIT_REPORT}
										isNonSubmit={false}
										disabled={
											disableSubmitButton ||
											isReadOnly ||
											(isSubmitOnBehalf && !selectedUserVerifiedInfo)
										}
										style={{ minWidth: '170px' }}
										onClick={() => handleOnSubmit()}
									>
										Submit Report
									</PrimaryButton>
								</span>
							</div>
						</div>
					)}
				</div>
				{imgSrcUrl && (
					<Lightbox
						mainSrc={imgSrcUrl}
						onCloseRequest={() => setImgSrcUrl(false)}
					/>
				)}
				{showImageUploadErrorModal && (
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
				)}
				<ConfirmationModal
					showModal={confirmationModal}
					closeModal={() => setConfirmationModal(false)}
					styles={modalStylesConfirmation}
					heading="Cancel Companion Horse Report Form"
					positiveLabel="Confirm"
					negativeLabel="Cancel"
					onClickPositive={() => cancelRequest()}
				>
					Are you sure you wish to delete this form?
				</ConfirmationModal>
			</PageContainer>
		</>
	);
};
export default CompanionHorseReport;
