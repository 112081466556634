import React from 'react';
import useStyles from './styles';
import { Row, Col, ScreenClassRender } from 'react-grid-system';
import FormButton from '../FormButton';
import MessageBar from '../MessageBar';
import { MessageBarTypeEnum } from '../../utils/enums';
import PropTypes from 'prop-types';

const InfoBar = ({
	id,
	infoText,
	buttonText,
	buttonOnClick,
	type = MessageBarTypeEnum.info ?? 'info',
	isDisabled,
	noButton,
	children,
	style
}) => {
	const classes = useStyles();

	const InfoBarButton = (mobile) => {
		return (
			<div className={mobile ? classes.mobileButton : classes.infoBarButton}>
				<FormButton
					id={id}
					title={buttonText}
					onClick={buttonOnClick}
					isDisabled={isDisabled}
				/>
			</div>
		);
	};

	return (
		<MessageBar type={type} style={style}>
			<Row>
				<Col
					xs={12}
					md={noButton ? 12 : 8}
					className={
						noButton ? classes.verticalCentreNoButton : classes.verticalCentre
					}
				>
					<div className={classes.infoBarText}>
						{infoText}
						{children}
					</div>
				</Col>
				{!noButton && (
					<Col xs={12} md={4}>
						<ScreenClassRender
							render={(screenClass) => (
								<>
									{['lg', 'xl', 'xxl'].includes(screenClass)
										? InfoBarButton(false)
										: InfoBarButton(true)}
								</>
							)}
						/>
					</Col>
				)}
			</Row>
		</MessageBar>
	);
};

InfoBar.propTypes = {
	type: PropTypes.oneOf(Object.keys(MessageBarTypeEnum)).isRequired
};

export default InfoBar;
